import { cloneElement, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames/bind";
import IconButton from "../IconButton";
import { withTranslation } from "@template/components/translation";
import { validate } from "../../../helpers/gesture";
import styles from "./index.css";
import stylesDeferred from "./deferred.css";
import { ENTER_CODE, ESCAPE_CODE } from "./constants";

const code = event => event.code;
const cx = classnames.bind(styles);

const Dropdown = ({
  body,
  children,
  footer,
  formatTranslation,
  header,
  isHidden,
  name,
  onOpen,
  onClose,
  isOpen,
  preventOpenOnMouseEnter,
  preventCloseOnMouseLeave,
  onTransitionEnd
}) => {
  const [isOpenState, setIsOpenState] = useState(false);
  const [isOpenOrTransitioning, setIsOpenOrTransitioning] = useState(false);
  const isDisplayed = isOpen || isOpenOrTransitioning;

  const bodyRef = useRef(null);
  const wrapperRef = useRef(null);

  const wrapperClassnames = cx(styles.wrapper, {
    [styles.wrapper__open]: isOpenState,
    [styles.wrapper__hidden]: isHidden
  });
  const contentClassnames = cx(styles.content, {
    [styles.content__displayed]: isDisplayed
  });

  const onClick = event => handleClick(event);

  const handleClick = event =>
    isOpen ? validate(event, onClose) : validate(event, onOpen);

  const onMouseEnter = event => {
    if (preventOpenOnMouseEnter) {
      return;
    }
    validate(event, onOpen);
  };

  const onMouseLeave = event => {
    if (preventCloseOnMouseLeave) {
      return;
    }
    validate(event, onClose);
  };

  const closeWithMouse = event => {
    event.stopPropagation();
    validate(event, onClose);
  };

  const handleKeyDown = event => {
    if (isOpen && code(event) === ESCAPE_CODE) {
      validate(event, onClose);
    }
  };

  const handleEnterKeyDown = event => {
    if (code(event) === ENTER_CODE) {
      onClose(event);
    }
  };

  /**
   * If the Dropdown is conditionally rendered, it may not animate if rendered and opened in the same frame.
   * Using a setTimeout fixes this issue by queuing the enter transition for the next phase.
   * Also fixes: https://asosmobile.atlassian.net/browse/WD-7466
   */
  useEffect(() => {
    let timeoutId;

    if (isOpen) {
      timeoutId = setTimeout(() => {
        setIsOpenState(true);
        setIsOpenOrTransitioning(true);
      });
    } else {
      setIsOpenState(false);
      const computedStyle = getComputedStyle(wrapperRef.current);
      const animationTime =
        parseInt(computedStyle.getPropertyValue("--transitionDelayValue")) +
        parseInt(computedStyle.getPropertyValue("--transitionDurationValue"));
      timeoutId = setTimeout(() => {
        setIsOpenOrTransitioning(false);
      }, animationTime);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isOpen]);

  const onDropdownTransitionEnd = () => {
    if (!isOpen) {
      bodyRef.current.scrollTop = 0;

      setIsOpenOrTransitioning(false);

      onTransitionEnd();
    }
  };

  return (
    // <div> element is catching clicks and key events from {children}
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      id={`${name}Dropdown`}
      className={wrapperClassnames}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onKeyDown={handleKeyDown}
      ref={wrapperRef}
    >
      {children}
      <div
        className={styles.outerWrapper}
        data-testid={`${name.toLowerCase()}-dropdown`}
        id={`${name.toLowerCase()}-dropdown`}
      >
        <div className={styles.innerWrapper}>
          <div
            className={styles.slidedown}
            onTransitionEnd={onDropdownTransitionEnd}
          >
            <div className={contentClassnames}>
              <div className={styles.header}>
                {header && cloneElement(header, { focusable: isOpen })}
                <IconButton
                  icon={stylesDeferred.close}
                  onClick={closeWithMouse}
                  onKeyDown={handleEnterKeyDown}
                  className={styles.close}
                  data-testid={`${name.toLowerCase()}-close-btn`}
                  aria-label={formatTranslation("icon_close")}
                />
              </div>
              <div className={styles.body} ref={bodyRef}>
                {body &&
                  cloneElement(body, {
                    focusable: isOpen,
                    queryParams: { nlid: "nav header" }
                  })}
              </div>
              {footer && (
                <div className={styles.footer}>
                  {cloneElement(footer, { focusable: isOpen })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  body: PropTypes.element,
  children: PropTypes.node,
  footer: PropTypes.element,
  formatTranslation: PropTypes.func.isRequired,
  header: PropTypes.element,
  isHidden: PropTypes.bool,
  isOpen: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onTransitionEnd: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  preventCloseOnMouseLeave: PropTypes.bool,
  preventOpenOnMouseEnter: PropTypes.bool
};

export default withTranslation(Dropdown);
