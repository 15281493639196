import sdkInstanceProvider from "@src/client/sdk/sdkInstanceProvider.js";
import {
  asosGlobalNamespace,
  siteChromeGlobalNamespace
} from "@template/constants";
import clientConfig from "@template/clientConfig";
import getSocialTags from "./getSocialTags";
import { viewportDimensions } from "../helpers/viewportDimensions";

export const setupPageDataEvent = async () => {
  if (!window[asosGlobalNamespace].webContext) {
    return;
  }

  const {
    affiliateId,
    browseCountry,
    browseCurrency,
    floor,
    platform,
    geoCountry,
    buildVersion
  } = window[asosGlobalNamespace].webContext.getWebContext();

  const { href, pathname, hostname, search: queryString } = window.location;
  const { title: pageTitle, referrer } = document;

  const {
    customer: { profile }
  } = await sdkInstanceProvider("identity");

  let isAuthenticated = undefined,
    customerId = undefined,
    firstTimeBuyer = undefined,
    isAnonymous = true;

  try {
    ({ isAuthenticated, isAnonymous, customerId, firstTimeBuyer } =
      await profile());
  } catch (_) {} // eslint-disable-line no-empty

  const socialTags = await getSocialTags({ isAnonymous, customerId });

  const { ENVIRONMENT_MODE } = clientConfig.get();

  window[asosGlobalNamespace][siteChromeGlobalNamespace].pushToDataLayer({
    pageData: {
      href: href,
      pagePath: pathname,
      pageTitle: pageTitle,
      hostname: hostname,
      queryString: queryString,
      browseCurrency: browseCurrency,
      browseCountry: browseCountry,
      channel: floor,
      deviceType: platform,
      referringUrl: referrer,
      env: ENVIRONMENT_MODE,
      localTimestamp: new Date().toISOString(),
      viewportDimensions: viewportDimensions(),
      webVersion: buildVersion,
      marketingData: {
        affid: affiliateId
      },
      customerData: {
        isAuthenticated: Boolean(isAuthenticated),
        customerId,
        firstTimeBuyer,
        hashedEmail: socialTags?.email,
        hashedFirstName: socialTags?.firstName,
        hashedLastName: socialTags?.lastName,
        hashedPhoneNumber: socialTags?.billingTelephoneMobile,
        geoIpCountryCode: geoCountry
      }
    }
  });
};
