import { SET_INVITED, SET_ITEM_COUNT, SET_OPTED_IN } from "./constants";

export const getLoyaltyStatus = state => state.loyalty;

const initialState = {
  invited: false,
  itemCount: null,
  optedIn: false
};

export const reduce = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_INVITED: {
      return {
        ...state,
        invited: payload.invited
      };
    }
    case SET_OPTED_IN: {
      return {
        ...state,
        optedIn: payload.optedIn
      };
    }
    case SET_ITEM_COUNT: {
      return {
        ...state,
        itemCount: payload.itemCount
      };
    }
    default:
      return state;
  }
};
