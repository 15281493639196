import PropTypes from "prop-types";
import { withTranslation } from "@template/components/translation";
import styles from "./LegalBar.css";
import { SMALL_VIEWPORT } from "@template/state/modules/layout/constants";

const imprintLinksByStores = {
  DE: "https://www.asos.com/de/entdecken/impressum/",
  ES: "https://www.asos.com/es/descubre/asos-details/",
  FR: "https://www.asos.com/fr/decouvrez/asos-details/",
  IT: "https://www.asos.com/it/scopri/asos-details/",
  DK: "https://www.asos.com/dk/udforsk/asos-details/",
  PL: "https://www.asos.com/pl/odkryj/asos-details/",
  NL: "https://www.asos.com/nl/ontdek/asos-details/",
  SE: "https://www.asos.com/se/upptack/asos-details/",
  ROE: "https://www.asos.com/discover/asos-details/"
};

const LegalBar = ({
  formatTranslation,
  defaultStoreUrl,
  viewport,
  storeCode
}) => {
  const shouldShowImprintLink = storeCode in imprintLinksByStores;

  return (
    <div className={styles.container}>
      <div className={styles.body} data-testid="legalbar">
        <p className={styles.copyright}>© {new Date().getFullYear()} ASOS</p>
        <ul className={styles.links}>
          {shouldShowImprintLink && (
            <li>
              <a
                className={styles.asosFocus}
                href={imprintLinksByStores[storeCode]}
              >
                {storeCode === "DE"
                  ? formatTranslation("legalbar_imprint")
                  : formatTranslation("asos_details")}
              </a>
              <span className={styles.pipe} aria-hidden="true" />
            </li>
          )}
          <li>
            <a
              className={styles.asosFocus}
              href={defaultStoreUrl.concat(
                formatTranslation("legalbar_privacy_link")
              )}
            >
              {storeCode === "DE" && viewport === SMALL_VIEWPORT
                ? formatTranslation("legalbar_privacy_abridged")
                : formatTranslation("legalbar_privacy")}
            </a>
            <span className={styles.pipe} aria-hidden="true" />
          </li>
          <li>
            <a
              className={styles.asosFocus}
              href={defaultStoreUrl.concat(
                formatTranslation("terms_and_conditions_link")
              )}
            >
              {formatTranslation("legalbar_terms")}
            </a>
            <span className={styles.pipe} aria-hidden="true" />
          </li>
          <li className={styles.hideOnSmallViewport}>
            <a
              className={styles.asosFocus}
              href={defaultStoreUrl.concat(
                formatTranslation("legalbar_accessibility_link")
              )}
            >
              {formatTranslation("legalbar_accessibility")}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

LegalBar.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  defaultStoreUrl: PropTypes.string.isRequired,
  viewport: PropTypes.string.isRequired,
  storeCode: PropTypes.string.isRequired
};

export default withTranslation(LegalBar);
