import PropTypes from "prop-types";
import { withTranslation } from "@template/components/translation";
import Notification from "../../Notification";

const ErrorMessage = ({
  formatTranslation,
  dismissBagError,
  showError = false
}) => {
  return showError ? (
    <Notification type="alert" onClose={dismissBagError}>
      <p>{formatTranslation(`minibag_errormessage_firstline`)}</p>
      <p>{formatTranslation(`minibag_errormessage_secondline`)}</p>
    </Notification>
  ) : null;
};

ErrorMessage.propTypes = {
  showError: PropTypes.bool,
  formatTranslation: PropTypes.func.isRequired,
  dismissBagError: PropTypes.func
};

export default withTranslation(ErrorMessage);
