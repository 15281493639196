import PropTypes from "prop-types";
import Dropdown from "./Dropdown";
import ClickOutside from "../ClickOutside";
import TabOutside from "../TabOutside";

const returnFocusToWidgetIcon = (e, name) => {
  e.preventDefault();
  const focusTarget = document.querySelector(`[data-testid='${name}Icon']`);

  return focusTarget && focusTarget.focus();
};

const DropdownContainer = ({
  body,
  children,
  close,
  footer,
  header,
  isHidden,
  isOpen,
  name,
  open,
  closed,
  preventCloseOnMouseLeave,
  preventOpenOnMouseEnter
}) => {
  return (
    <TabOutside disabled={!isOpen} callback={close}>
      <ClickOutside
        disabled={!isOpen}
        callback={close}
        validPropagationTargets={["#chrome-header", "#chrome-breadcrumb"]}
        stopPropagation={false}
      >
        <Dropdown
          body={body}
          footer={footer}
          header={header}
          isOpen={isOpen}
          isHidden={isHidden}
          name={name}
          onClose={e => {
            close();
            returnFocusToWidgetIcon(e, name);
          }}
          onOpen={open}
          onTransitionEnd={closed}
          preventCloseOnMouseLeave={preventCloseOnMouseLeave}
          preventOpenOnMouseEnter={preventOpenOnMouseEnter}
        >
          {children}
        </Dropdown>
      </ClickOutside>
    </TabOutside>
  );
};

DropdownContainer.propTypes = {
  body: PropTypes.node,
  children: PropTypes.node,
  close: PropTypes.func,
  footer: PropTypes.node,
  header: PropTypes.node,
  isHidden: PropTypes.bool,
  isOpen: PropTypes.bool,
  name: PropTypes.string.isRequired,
  open: PropTypes.func,
  closed: PropTypes.func,
  preventCloseOnMouseLeave: PropTypes.bool,
  preventOpenOnMouseEnter: PropTypes.bool
};

DropdownContainer.defaultProps = {
  closed: PropTypes.func
};

export default DropdownContainer;
