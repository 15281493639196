import request from "@src/api/request";
import {
  NEW_IN_TODAY_WOMEN_CATEGORY_ID,
  NEW_IN_TODAY_MEN_CATEGORY_ID,
  SEARCH_RESULTS_LIMIT
} from "./constants";
import { getCookieValue } from "../context/cookies";

export const getNewInItemCount = async isMale => {
  const category = isMale
    ? NEW_IN_TODAY_MEN_CATEGORY_ID
    : NEW_IN_TODAY_WOMEN_CATEGORY_ID;

  const store = getCookieValue("storeCode");
  const lang = getCookieValue("browseLanguage");
  const currency = getCookieValue("browseCurrency");
  const country = getCookieValue("browseCountry");

  try {
    const { itemCount } = await request(
      `/api/product/search/v2/categories/${category}`,
      {
        params: {
          store,
          lang,
          currency,
          country,
          limit: SEARCH_RESULTS_LIMIT
        }
      }
    );

    return itemCount;
  } catch (_) {
    throw new Error("Error fetching new in item count");
  }
};
