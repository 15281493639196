import { withTranslation } from "@template/components/translation";
import styles from "./LoyaltyNewInBannerItem.css";
import PropTypes from "prop-types";

import asosWorldLogo from "./assets/asos-world-logo.svg";
import padlock from "./assets/padlock.svg";
import Button from "@template/components/Button";
import {
  NEW_IN_TODAY_WOMEN_CATEGORY_ID,
  WOMEN_URL_PATH,
  NEW_IN_TODAY_MEN_CATEGORY_ID,
  MEN_URL_PATH
} from "./constants";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const LoyaltyNewInBannerItem = ({
  defaultStoreUrl,
  formatTranslation,
  isMale,
  isOptedIn,
  itemCount
}) => {
  const gender = isMale ? MEN_URL_PATH : WOMEN_URL_PATH;
  const categoryId = isMale
    ? NEW_IN_TODAY_MEN_CATEGORY_ID
    : NEW_IN_TODAY_WOMEN_CATEGORY_ID;

  const includeItemCount = itemCount && itemCount >= 100;

  const newInTodayUrl = defaultStoreUrl.concat(
    formatTranslation("new_in_today_link", {
      gender,
      categoryId
    })
  );

  const lowItemCountTitle = isOptedIn
    ? formatTranslation("navigation_loyalty_new_in_title_opted_in")
    : formatTranslation("navigation_loyalty_new_in_title_opted_out");

  const text = isOptedIn
    ? formatTranslation("navigation_loyalty_new_in_text_opted_in")
    : formatTranslation("navigation_loyalty_new_in_text_opted_out");

  return (
    <>
      <div
        className={cx(styles.grid, {
          [styles.removeWhiteSpace]: isOptedIn
        })}
      >
        <div
          className={cx(styles.contentContainer, {
            [styles.contentContainerPadding]: isOptedIn,
            [styles.narrowContentContainer]: includeItemCount
          })}
        >
          <img src={asosWorldLogo} className={styles.logo} alt="ASOS World" />

          {includeItemCount ? (
            <h4 className={styles.highCountTitle}>
              <span className={styles.itemCount}>{itemCount}</span>
              {formatTranslation("navigation_loyalty_new_in_title_with_count")}
            </h4>
          ) : (
            <h4 className={styles.lowCountTitle}>{lowItemCountTitle}</h4>
          )}

          <p className={styles.content}>{text}</p>
        </div>

        <div
          className={cx(styles.imageContainer, {
            [styles.wideImageContainer]: includeItemCount
          })}
        >
          {!isOptedIn && (
            <img src={padlock} className={styles.padlock} alt="Lock icon" />
          )}
        </div>
      </div>

      {!isOptedIn && (
        <div className={styles.linksContainer}>
          <>
            <Button href={newInTodayUrl} theme="violet">
              {formatTranslation("navigation_loyalty_new_in_join_button")}
            </Button>

            <a
              className={styles.link}
              href={defaultStoreUrl.concat(
                formatTranslation("terms_and_conditions_link")
              )}
            >
              {formatTranslation("navigation_loyalty_new_in_terms_link")}
            </a>
          </>
        </div>
      )}
    </>
  );
};

LoyaltyNewInBannerItem.propTypes = {
  defaultStoreUrl: PropTypes.string.isRequired,
  formatTranslation: PropTypes.func.isRequired,
  isMale: PropTypes.bool.isRequired,
  isOptedIn: PropTypes.bool.isRequired,
  itemCount: PropTypes.number
};

export default withTranslation(LoyaltyNewInBannerItem);
