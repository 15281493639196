import PropTypes from "prop-types";
import classnames from "classnames/bind";

import styles from "./deferred.css";

const cx = classnames.bind(styles);

const TransitionList = ({ children, itemDeletedLabel, onTransitionEnd }) => (
  <ul className={styles.transitionList}>
    {children.map(child => {
      const isExiting = child.props.transition === "exiting";

      const classes = cx("transitionList__element", {
        "transitionList__element--exiting": isExiting
      });

      return (
        <li key={child.key} className={styles.wrapper}>
          <div
            className={classes}
            onTransitionEnd={e => {
              if (e.nativeEvent.propertyName === "transform") {
                onTransitionEnd(child.props.id);
              }
            }}
          >
            <div
              className={styles.transitionList_itemDeletedLabel}
              role="alert"
            >
              {isExiting ? itemDeletedLabel : ""}
            </div>
            {child}
            <div className={styles.transitionList__overlay} />
          </div>
        </li>
      );
    })}
  </ul>
);

TransitionList.propTypes = {
  children: PropTypes.node.isRequired,
  itemDeletedLabel: PropTypes.string,
  onTransitionEnd: PropTypes.func
};

export default TransitionList;
