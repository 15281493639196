import orderBy from "lodash/orderBy";
import { LARGE_VIEWPORT } from "../../layout";
import {
  MEN_NAVIGATION_CATEGORIES_ID,
  MEN_NAVIGATION_NEW_IN_CATEGORY_ID,
  NEW_IN_TODAY_BANNER_ID,
  NEW_IN_TODAY_BANNER_ITEM_ID,
  WOMEN_NAVIGATION_CATEGORIES_ID,
  WOMEN_NAVIGATION_NEW_IN_CATEGORY_ID
} from "./constants";

const sort = (items, viewport) =>
  viewport === LARGE_VIEWPORT
    ? orderBy(items, ["largeScreenPriority"], ["desc"])
    : items;

const shouldExcludeForViewport = (item, viewport) =>
  item.excludes.includes(viewport);

export const filterTreeByViewport = (tree, forViewport) => {
  return sort(
    tree.reduce((filteredTree, item) => {
      if (shouldExcludeForViewport(item, forViewport)) {
        return filteredTree;
      }
      filteredTree.push({
        ...item,
        items: sort(filterTreeByViewport(item.items, forViewport), forViewport)
      });
      return filteredTree;
    }, []),
    forViewport
  );
};

export const addNewInTodayBanner = tree => {
  const newInCategoryItems = tree
    .find(
      ({ id }) =>
        id === WOMEN_NAVIGATION_CATEGORIES_ID ||
        id === MEN_NAVIGATION_CATEGORIES_ID
    )
    ?.items.find(
      ({ id }) =>
        id === WOMEN_NAVIGATION_NEW_IN_CATEGORY_ID ||
        id === MEN_NAVIGATION_NEW_IN_CATEGORY_ID
    )?.items;

  if (!newInCategoryItems) {
    return tree;
  }

  newInCategoryItems.shift();

  newInCategoryItems.unshift({
    id: NEW_IN_TODAY_BANNER_ID,
    excludes: ["large-viewport"],
    smallAndMediumTemplateId: 5,
    items: [
      {
        id: NEW_IN_TODAY_BANNER_ITEM_ID,
        excludes: ["large-viewport"],
        optedIn: false
      }
    ]
  });

  return tree;
};
