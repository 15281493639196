export const NEW_IN_TODAY_BANNER_ID = "3ab4c589-41b3-498b-97c0-6031c29a278f";
export const NEW_IN_TODAY_BANNER_ITEM_ID =
  "3192af78-b7b4-4d7a-b149-b98a59fcd20f";

export const WOMEN_NAVIGATION_CATEGORIES_ID =
  "aa8928ea-caab-4396-8835-28c56a57982f";
export const WOMEN_NAVIGATION_NEW_IN_CATEGORY_ID =
  "1020946c-8949-4e9c-9719-43435002bcd4";
export const MEN_NAVIGATION_CATEGORIES_ID =
  "bb2385ab-3f26-48cd-80f3-e7414bfb112b";
export const MEN_NAVIGATION_NEW_IN_CATEGORY_ID =
  "029c47b3-2111-43e9-9138-0d00ecf0b3db";
