import { getLoyaltyInvitedStatus } from "./getLoyaltyInvitedStatus";
import {
  SET_INVITED,
  SET_ITEM_COUNT,
  SET_OPTED_IN
} from "@template/state/modules/loyalty/constants";
import { getNewInItemCount } from "./getNewInItemCount";
import { isMale } from "@template/state/modules/layout";
import { noticeError } from "@src/helpers/monitoring";

export const loadLoyaltyStatus = async ({
  dispatch,
  getState,
  customerTierId
}) => {
  const isInvited = await getLoyaltyInvitedStatus();

  if (!isInvited) {
    return;
  }

  dispatch({
    type: SET_INVITED,
    payload: { invited: true }
  });

  if (customerTierId) {
    return dispatch({
      type: SET_OPTED_IN,
      payload: { optedIn: true }
    });
  }

  const state = getState();
  const isMaleUser = isMale(state);

  try {
    const itemCount = await getNewInItemCount(isMaleUser);

    return dispatch({
      type: SET_ITEM_COUNT,
      payload: { itemCount }
    });
  } catch (error) {
    noticeError(error);
  }
};
