import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./deferred.css";
import Modal from "../../Modal";
import ContentLoadingOverlay from "./ContentLoadingOverlay";
import { useEventListener } from "../../useEventListener";
import { MODAL_TYPES } from "../../constants";
import { IFRAME_MESSAGES } from "./constants";
import { usePublicApiManagement } from "../usePublicApiManagement";
import { getIFrameLoadFailureHandler } from "./getIFrameLoadFailureHandler";
import { useErrorLogging } from "./useErrorLogging";
import { validateMessage } from "./validateMessage";
import { validateOpenIFrameModalParams } from "./validateOpenIFrameModalParams";
import { withParams } from "@src/helpers/queryString";

const {
  RECEIVE_MODAL_READY,
  RECEIVE_MODAL_CLOSE_TRIGGERED,
  SEND_FOCUS_ON_CLOSE_BUTTON
} = IFRAME_MESSAGES;

const PublicIFrameModal = ({ isOpen, setIsOpen }) => {
  const [contentReady, setContentReady] = useState(false);
  const [iFrameUrl, setIFrameUrl] = useState(null);
  const [iFrameTitle, setIFrameTitle] = useState(null);
  const [error, setError] = useState(null);
  const [modalType, setModalType] = useState(MODAL_TYPES.SIDE_RIGHT);
  const [forceDOMRewrite, setForceDOMRewrite] = useState(true);

  const iFrameRef = useRef(null);
  const focusOnCloseElementRef = useRef(null);

  const onClosedDelegate = () => focusOnCloseElementRef.current?.focus();
  const close = () => setIsOpen(false);

  const handleMessageFromIFrame = ({ data, origin, source }) => {
    if (
      !validateMessage({
        iframe: iFrameRef.current?.contentWindow,
        origin,
        source
      })
    ) {
      return;
    }

    if (data === RECEIVE_MODAL_CLOSE_TRIGGERED) {
      close();
    }

    if (data === RECEIVE_MODAL_READY) {
      setContentReady(true);
    }

    if (data.event === RECEIVE_MODAL_READY) {
      if (!data.iFrameTitle) {
        setError(
          `${RECEIVE_MODAL_READY} event was received, but no iFrameTitle value was specified.`
        );
      } else {
        setIFrameTitle(data.iFrameTitle);
        setContentReady(true);
      }
    }
  };

  useEffect(() => {
    if (error) {
      setContentReady(false);
    }
  }, [error]);

  useEffect(() => {
    if (contentReady) {
      setError(null);
    }
  }, [contentReady]);

  useEffect(() => {
    if (isOpen && contentReady && iFrameRef.current) {
      iFrameRef.current.contentWindow.focus();
      iFrameRef.current.contentWindow.postMessage(
        SEND_FOCUS_ON_CLOSE_BUTTON,
        window.location.origin
      );
    }
  }, [isOpen, contentReady, iFrameRef]);

  useEventListener("message", handleMessageFromIFrame);
  useErrorLogging(error);

  const openIFrameModal = ({
    url,
    focusOnCloseElement,
    consumer,
    iFrameTitle,
    modalType,
    forceDOMRewrite = true
  }) => {
    const urlWithConsumer = withParams(url, { consumer });

    validateOpenIFrameModalParams({
      url: urlWithConsumer,
      focusOnCloseElement,
      consumer,
      iFrameTitle,
      modalType,
      forceDOMRewrite
    });

    if (forceDOMRewrite) {
      setContentReady(false);
      setError(null);
    }

    setIFrameTitle(iFrameTitle);
    setIFrameUrl(urlWithConsumer);
    focusOnCloseElementRef.current = focusOnCloseElement;
    setModalType(modalType);
    setForceDOMRewrite(forceDOMRewrite);
    setIsOpen(true);
  };

  usePublicApiManagement({ openIFrameModal });

  return (
    <Modal
      {...{
        isOpen,
        close,
        onClosedDelegate,
        modalType,
        iFrameTitle,
        forceDOMRewrite: forceDOMRewrite || !!error
      }}
    >
      <ContentLoadingOverlay
        showError={!!error}
        contentReady={contentReady}
        closeButtonClickDelegate={close}
        moveFocusToCloseButton={isOpen}
      />
      <iframe
        className={styles.iFrame}
        data-testid="public-modal-iframe"
        frameBorder="0"
        seamless="seamless"
        title={iFrameTitle}
        sandbox="allow-scripts allow-same-origin"
        src={iFrameUrl}
        ref={iFrameRef}
        onLoad={getIFrameLoadFailureHandler(setError)}
      />
    </Modal>
  );
};

PublicIFrameModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
};

export default PublicIFrameModal;
