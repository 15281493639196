import PropTypes from "prop-types";
import { withTranslation } from "@template/components/translation";
import styles from "./index.css";

const MinibagHeader = ({ totalQuantity = 0, formatTranslation }) => (
  <h2 data-test-id="miniBagHeader" className={styles.container}>
    {formatTranslation("minibag_mybag")},
    <span data-test-id="miniBagItemCount" className={styles.totalQuantity}>
      {formatTranslation("minibag_itemcount", {
        smart_count: totalQuantity
      })}
    </span>
  </h2>
);

MinibagHeader.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  totalQuantity: PropTypes.number
};

export default withTranslation(MinibagHeader);
