import loyaltySdk from "@asosteam/asos-loyalty-sdk";
import clientConfig from "@template/clientConfig";
import {
  asosGlobalNamespace,
  sdkGlobalNamespace
} from "../../template/constants";
import { eventBus } from "@src/helpers/eventing/events";
import logger from "@src/helpers/logger";

export const initialise = async (
  { browseLanguage, browseCountry, keyStoreDataversion },
  { getAuthHeader, refreshToken, getClaim }
) => {
  const { origin } = new URL(window.location.href);
  const fullUrl = `${origin}/api`;

  const loyaltySdkInstance = loyaltySdk({
    baseUrl: fullUrl,
    xSiteOrigin: clientConfig.getXSiteOrigin(),
    authClient: {
      getAuthHeader,
      refreshToken,
      getClaim
    },
    logger,
    language: browseLanguage,
    country: browseCountry,
    keyStoreDataversion: keyStoreDataversion
  });

  await loyaltySdkInstance.initialize();

  return loyaltySdkInstance;
};

export const waitForLoyaltySDK = () =>
  new Promise(resolve => {
    const {
      [asosGlobalNamespace]: { [sdkGlobalNamespace]: { loyalty } = {} } = {}
    } = window;

    if (loyalty) {
      resolve(loyalty);
    } else {
      eventBus.addListener("loyalty-sdk-ready", resolve);
    }
  });
