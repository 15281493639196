import { connect } from "react-redux";
import LoyaltyNewInBannerItem from "./LoyaltyNewInBannerItem";

import { getDefaultStoreUrl } from "@template/state/modules/regionalStore";
import { isMale } from "@template/state/modules/layout";
import { getLoyaltyStatus } from "@template/state/modules/loyalty";

function mapStateToProps(state) {
  return {
    defaultStoreUrl: getDefaultStoreUrl(state),
    isMale: isMale(state),
    isOptedIn: getLoyaltyStatus(state).optedIn,
    itemCount: getLoyaltyStatus(state).itemCount
  };
}

export default connect(mapStateToProps)(LoyaltyNewInBannerItem);
