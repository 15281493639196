import { Component } from "react";
import PropTypes from "prop-types";
import styles from "./Container.css";
import classnames from "classnames/bind";
import { globalEventHandler, events, keys } from "../../globalEventHandler";

const cx = classnames.bind(styles);

class Container extends Component {
  static propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
    withError: PropTypes.bool,
    isLoading: PropTypes.bool,
    lang: PropTypes.string
  };

  static childContextTypes = {
    showWarning: PropTypes.func
  };

  state = { warningShowing: true };

  getChildContext() {
    return {
      showWarning: this.showWarning.bind(this)
    };
  }

  /* istanbul ignore next */
  componentDidMount() {
    this.globalKeyDownListener = globalEventHandler.addListener(
      events.keyDown,
      event => {
        if (event.key === keys.escape) {
          this.handleClose(event.originalEvent);
        }
      }
    );
  }

  /* istanbul ignore next */
  componentWillUnmount() {
    this.globalKeyDownListener && this.globalKeyDownListener.remove();
  }

  handleClose = /* istanbul ignore next */ e => {
    const { onClose } = this.props;
    e.preventDefault();
    onClose();
  };

  showWarning(isShowing) {
    if (isShowing) this.wrapper.scrollTop = this.wrapper.scrollHeight;
    this.setState({
      warningShowing: isShowing
    });
  }

  render() {
    const { children, withError, isLoading, lang } = this.props;

    return (
      <div className={styles.wrapper} ref={el => (this.wrapper = el)}>
        <div
          className={cx([styles.container], {
            [styles.container__withError]: withError,
            [styles.container__notLoading]: !isLoading
          })}
          lang={lang}
        >
          {children}
        </div>
      </div>
    );
  }
}

Container.defaultProps = {
  lang: ""
};

export default Container;
