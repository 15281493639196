import PropTypes from "prop-types";
import classnamesBind from "classnames/bind";
import styles from "./deferred.css";
import { MODAL_TYPES } from "../constants";
import { useEventListener } from "../useEventListener";
import PageOverlay from "./PageOverlay";
import { useBlockScroll } from "./useBlockScroll";
import { useRenderManagement } from "./useRenderManagement";
import { getClassnamesForModalType } from "./getClassnamesForModalType";
import { useAriaHidingMainApp } from "./useAriaHidingMainApp";

const classnames = classnamesBind.bind(styles);

const Modal = ({
  isOpen,
  onClosedDelegate,
  close,
  children,
  modalType,
  iFrameTitle,
  forceDOMRewrite = false
}) => {
  const modalClassnames = getClassnamesForModalType(modalType);

  const { shouldRenderModal, shouldRenderPageOverlay } = useRenderManagement({
    isOpen,
    modalType,
    onClosedDelegate,
    forceDOMRewrite
  });

  const handleKeyDown = event => {
    if (event.key === "Escape") {
      close();
    }
  };

  useEventListener("keydown", handleKeyDown);
  useBlockScroll(isOpen);
  useAriaHidingMainApp(isOpen);

  return shouldRenderModal ? (
    <>
      {shouldRenderPageOverlay && (
        <PageOverlay
          onClickDelegate={close}
          modalClassnames={modalClassnames}
        />
      )}
      <div
        data-testid="modal"
        role="dialog"
        aria-modal="true"
        aria-label={iFrameTitle || ""}
        className={classnames(styles.modal, {
          isOpen,
          ...modalClassnames
        })}
      >
        {children}
      </div>
    </>
  ) : null;
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onClosedDelegate: PropTypes.func,
  modalType: PropTypes.oneOf(Object.values(MODAL_TYPES)).isRequired,
  iFrameTitle: PropTypes.string,
  children: PropTypes.node,
  forceDOMRewrite: PropTypes.bool
};

export default Modal;
