import { connect } from "react-redux";
import MiniBag from "./MiniBag";
import {
  getBagItems,
  getLoading,
  bagItemDeleteTransitionEnd
} from "../../state/modules/bag";
import { getDropdownIsOpen } from "../../state/modules/dropdown";
import {
  getDefaultStoreUrl,
  getDefaultLanguage
} from "../../state/modules/regionalStore";

const mapStateToProps = (state, props) => {
  return {
    defaultStoreUrl: getDefaultStoreUrl(state),
    items: getBagItems(state),
    lang: getDefaultLanguage(state),
    loading: getLoading(state),
    isOpen: getDropdownIsOpen(state, props)
  };
};

const mapDispatchToProps = {
  bagItemDeleteTransitionEnd
};

export default connect(mapStateToProps, mapDispatchToProps)(MiniBag);
