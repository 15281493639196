import {
  asosGlobalNamespace,
  audiencesSdkGlobalNamespace,
  sdkGlobalNamespace
} from "@template/constants";
import clientConfig from "@template/clientConfig";
import { noticeError } from "@src/helpers/monitoring";

export const getLoyaltyInvitedStatus = async () => {
  const { LOYALTY_SEGMENT_ID } = clientConfig.get();

  let segments = [];

  try {
    segments =
      await window[asosGlobalNamespace][sdkGlobalNamespace][
        audiencesSdkGlobalNamespace
      ].getSegments();
  } catch (error) {
    noticeError(error);
    return false;
  }

  const isInvited = segments.some(
    ({ segmentId }) => segmentId === LOYALTY_SEGMENT_ID
  );

  return isInvited;
};
