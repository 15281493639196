import PropTypes from "prop-types";
import styles from "./deferred.css";
import { withTranslation } from "@template/components/translation";
import {
  trackMiniBagProductDelete,
  ANALYTICS_CONTEXT
} from "../../../../../helpers/eventing/events";
import Icon from "@template/components/Icon";

const DeleteItem = ({
  deleteBagItem,
  id,
  itemId,
  formatTranslation,
  quantity,
  price,
  setDeletingItem
}) => {
  function deleteBagItemWithTracking(id) {
    deleteBagItem(id);
    trackMiniBagProductDelete({
      context: ANALYTICS_CONTEXT.minibagDeleteItem,
      itemId,
      quantity,
      priceValue: price?.value || price?.current?.value
    });
  }

  return (
    <button
      aria-label={formatTranslation("minibag_delete_bag_item_text")}
      className={styles.deleteItem}
      onClick={e => {
        e.stopPropagation();
        deleteBagItemWithTracking(id);
        setDeletingItem(id);
      }}
    >
      <Icon icon={styles.deleteItemIcon} />
    </button>
  );
};

DeleteItem.propTypes = {
  deleteBagItem: PropTypes.func.isRequired,
  formatTranslation: PropTypes.func,
  id: PropTypes.string.isRequired,
  itemId: PropTypes.number.isRequired,
  price: PropTypes.shape({
    value: PropTypes.number,
    current: PropTypes.shape({
      value: PropTypes.number
    })
  }),
  quantity: PropTypes.number,
  setDeletingItem: PropTypes.func.isRequired
};

export default withTranslation(DeleteItem);
