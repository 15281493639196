import {
  asosGlobalNamespace,
  siteChromeGlobalNamespace
} from "@template/constants";
import { onFCP, onTTFB } from "web-vitals";

export const setupWebVitalsEvent = async () => {
  const metrics = {
    FCP: "firstContentfulPaintData",
    TTFB: "timeToFirstByteData"
  };

  const pushMetric = ({ name, value }) => {
    const dataLayerEventKey = metrics[name];

    if (dataLayerEventKey) {
      window[asosGlobalNamespace][siteChromeGlobalNamespace].pushToDataLayer({
        [dataLayerEventKey]: {
          value: value
        }
      });
    }
  };

  onFCP(pushMetric);
  onTTFB(pushMetric);
};
