import { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "@template/components/translation";
import CountrySelectorButton from "../CountrySelectorButton";
import { ANALYTICS_POSITION_HEADER } from "../../../helpers/eventing/events";
import styles from "./MiscBar.css";

class MiscBar extends Component {
  render() {
    const { formatTranslation, defaultStoreUrl } = this.props;

    return (
      <div className={styles.container} data-testid="topbar">
        <div className={styles.body}>
          <ul className={styles.navigation}>
            <li>
              <a
                href={defaultStoreUrl.concat(
                  formatTranslation("miscbar_helplink")
                )}
                data-testid="help"
                className={styles.link}
              >
                {formatTranslation("miscbar_help")}
              </a>
            </li>
            <li>
              <CountrySelectorButton position={ANALYTICS_POSITION_HEADER} />
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

MiscBar.propTypes = {
  formatTranslation: PropTypes.func.isRequired,
  defaultStoreUrl: PropTypes.string.isRequired
};

export default withTranslation(MiscBar);
