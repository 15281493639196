import { ALWAYS_INCLUDED_BENEFIT_TYPES } from "./constants";

export function filterLoyaltyBenefitItems(
  navigation,
  currentTierId,
  loyaltySdk
) {
  function filterItems(items) {
    return items.reduce((acc, item) => {
      const filteredChildren = item.items?.length
        ? filterItems(item.items)
        : [];

      if (
        !item.loyaltyBenefitType ||
        ALWAYS_INCLUDED_BENEFIT_TYPES.includes(item.loyaltyBenefitType)
      ) {
        acc.push(
          filteredChildren.length
            ? { ...item, items: filteredChildren }
            : { ...item }
        );
        return acc;
      }

      if (!loyaltySdk || !currentTierId) {
        return acc;
      }

      const isBenefitAvailable = loyaltySdk.isBenefitAvailableForTier(
        currentTierId,
        item.loyaltyBenefitType
      );

      if (isBenefitAvailable) {
        acc.push(
          filteredChildren.length
            ? { ...item, items: filteredChildren }
            : { ...item }
        );
      }

      return acc;
    }, []);
  }

  return {
    ...navigation,
    tree: filterItems(navigation.tree)
  };
}
