import { LARGE_VIEWPORT } from "../../layout";

import {
  addNewInTodayBanner,
  filterTreeByViewport
} from "./filterNavigationTree";

export const mapSidePrimaryNavigationForViewport = (
  tree,
  forViewport,
  loyaltyStatus
) => {
  const filteredTreeByViewport = filterTreeByViewport(tree, forViewport);

  if (!loyaltyStatus || !loyaltyStatus.invited) {
    return filteredTreeByViewport;
  }

  return addNewInTodayBanner(filteredTreeByViewport);
};

export const mapLargePrimaryNavigation = tree =>
  filterTreeByViewport(tree, LARGE_VIEWPORT).reduce(
    (items, secondLevelItems) => items.concat(secondLevelItems.items),
    []
  );
