import {
  getBagSummary,
  getBag,
  subscribeToUpdates,
  removeItem,
  startCheckout
} from "@src/client/sdk/bag";
import {
  BAG_ERROR,
  BAG_ITEM_DELETE_TRANSITION_END,
  FINISH_REMOVE_BAG_ITEM,
  getBagItems,
  getTotalQuantity,
  SET_BAG,
  SET_LOADING,
  START_CHECKOUT,
  START_REMOVE_BAG_ITEM
} from "@template/state/modules/bag";
import {
  SET_NOTIFICATION,
  HIDE_NOTIFICATION,
  getNotificationData,
  getMinibagNotification,
  MINIBAG as MINIBAG_NOTIFICATION
} from "@template/state/modules/notifications";
import {
  OPEN_DROPDOWN,
  MINIBAG,
  getDropdownIsOpen,
  DROPDOWN_CLOSED
} from "@template/state/modules/dropdown";
import { getSecureStoreUrl } from "@template/state/modules/regionalStore";
import { withParams } from "@src/helpers/queryString";
import miniBagEnabled from "@template/state/middleware/bag/helpers/miniBagEnabled";
import bagWithProductLinks from "@template/state/middleware/bag/helpers/bagWithProductLinks";
import pageSupportsMiniBagWithDropdown from "@template/helpers/pageSupportsMiniBagWithDropdown";
import { getBagCheckoutPromptMVTStatus } from "@template/state/modules/infoBanner";

const bagMiddleware = ({ getState, dispatch }) => {
  getBagSummary().then(summary =>
    dispatch({
      type: SET_BAG,
      payload: { bag: { summary } }
    })
  );

  subscribeToUpdates(({ bag, messages, sellerChanged, containsHotProduct }) => {
    const state = getState();
    const current = getBagItems(state) || [];
    const next = getBagItems({ bag });
    const oldQuantity = getTotalQuantity(state);
    const newQuantity = getTotalQuantity({ bag });

    const removed = current.filter(
      ({ id }) => !next.find(item => item.id === id)
    );
    const miniBagIsEnabled = miniBagEnabled(state);

    if (miniBagIsEnabled && removed.length) {
      const items = current;

      if (!pageSupportsMiniBagWithDropdown(window.location.pathname)) {
        dispatch({
          type: SET_BAG,
          payload: {
            bag: {
              ...bagWithProductLinks({ ...state.bag, items }, getState),
              incoming: bag,
              isUpdated: true,
              summary: {
                totalQuantity: newQuantity
              }
            },
            messages
          }
        });
      } else {
        dispatch({
          type: SET_BAG,
          payload: {
            bag: {
              ...bagWithProductLinks({ ...state.bag, items }, getState),
              incoming: bag,
              isUpdated: true
            },
            messages
          }
        });
      }

      return;
    }

    dispatch({
      type: SET_BAG,
      payload: {
        bag: {
          ...bagWithProductLinks(bag, getState),
          isUpdated: true
        },
        messages
      }
    });

    if (newQuantity > oldQuantity) {
      if (!miniBagIsEnabled) {
        return;
      }

      dispatch({
        type: SET_NOTIFICATION,
        payload: {
          notificationType: MINIBAG_NOTIFICATION,
          notificationData: {
            isVisible: true,
            ...getNotificationData({
              sellerChanged,
              containsHotProduct,
              itemType: bag.items[0].itemType
            })
          }
        }
      });

      dispatch({
        type: OPEN_DROPDOWN,
        name: MINIBAG,
        programmatic: true
      });
    }
  });

  return next => action => {
    const state = getState();

    if (!miniBagEnabled(state) && !getBagCheckoutPromptMVTStatus(state)) {
      next(action);
      return;
    }

    if (action.type === START_REMOVE_BAG_ITEM) {
      dispatch({
        type: "DEFER_ACTIONS",
        payload: {
          actionsToDefer: [OPEN_DROPDOWN],
          actionToWaitFor: BAG_ITEM_DELETE_TRANSITION_END,
          actionsToCancelOn: [BAG_ERROR]
        }
      });

      const items = getBagItems(state);
      const item = items.find(({ id }) => id === action.item);

      removeItem(item.id, item.itemType.toLowerCase())
        .then(() => {
          dispatch({ type: FINISH_REMOVE_BAG_ITEM, itemId: action.item });
        })
        .catch(() => dispatch({ type: BAG_ERROR }));
    }

    if (
      action.type === OPEN_DROPDOWN &&
      action.name === MINIBAG &&
      !action.programmatic
    ) {
      const miniBagDropdownIsOpen = getDropdownIsOpen(state, { name: MINIBAG });

      if (!miniBagDropdownIsOpen) {
        dispatch({ type: SET_LOADING });
        getBag()
          .then(({ bag, messages }) => {
            dispatch({
              type: SET_BAG,
              payload: {
                bag: bagWithProductLinks(bag, getState),
                messages
              }
            });

            if (bag.items.length) {
              dispatch({
                type: SET_NOTIFICATION,
                payload: {
                  notificationType: MINIBAG_NOTIFICATION,
                  notificationData: {
                    ...getMinibagNotification(getState())
                  }
                }
              });
            }
          })
          .catch(() => dispatch({ type: BAG_ERROR }));
      }
    }

    if (action.type === DROPDOWN_CLOSED && action.name === MINIBAG) {
      dispatch({
        type: HIDE_NOTIFICATION,
        payload: {
          notificationType: MINIBAG_NOTIFICATION
        }
      });

      /* if the dropdown closed after the last item was removed then we still need to update state with the latest items... */
      const incoming = state.bag.incoming;

      if (incoming && incoming.items.length === 0) {
        dispatch({
          type: SET_BAG,
          payload: {
            bag: bagWithProductLinks(
              { ...incoming, incoming: undefined },
              getState
            )
          }
        });
      }
    }

    if (
      action.type === BAG_ITEM_DELETE_TRANSITION_END &&
      action.itemId === state.bag.lastItemRemoved
    ) {
      const incoming = state.bag.incoming;
      dispatch({
        type: SET_BAG,
        payload: {
          bag: bagWithProductLinks(
            { ...incoming, incoming: undefined },
            getState
          )
        }
      });
      dispatch({
        type: HIDE_NOTIFICATION,
        payload: {
          notificationType: MINIBAG_NOTIFICATION
        }
      });
    }
    if (action.type === START_CHECKOUT) {
      const state = getState();
      const checkoutLink = getSecureStoreUrl(state);
      startCheckout()
        .then(() => {
          const checkoutUrl = checkoutLink + "initialise";
          window.location.replace(
            withParams(checkoutUrl, { ctaref: "mini bag" })
          );
        })
        .catch(console.err);
    }

    return next(action);
  };
};

export default bagMiddleware;
